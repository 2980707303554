// TODO: a11y

import Popper from "popper.js"

import { domReady } from "../../utils/dom"

const popperConfig = {
  placement: `bottom-start`,
  modifiers: {
    flip: {
      behavior: [`bottom`],
    },
  },
}

let activeTrigger = null
let activeTarget = null
let activePopper = null

const doShow = (trigger) => {
  const name = trigger.getAttribute(`href`).replace(`#`, ``)
  const target = document.querySelector(`#${name}`)

  target.classList.add(`--active`)
  trigger.classList.add(`--active`)
  const popper = new Popper(trigger, target, popperConfig)

  // target.setAttribute('tabindex', 0);
  // target.focus();

  activeTrigger = trigger
  activeTarget = target
  activePopper = popper
}

const doHide = () => {
  activeTrigger.classList.remove(`--active`)
  activeTarget.classList.remove(`--active`)
  activePopper.destroy()

  activeTrigger = null
  activeTarget = null
  activePopper = null
}

domReady(() => {
  if (!document.querySelector(`.content-grid`)) return

  document.addEventListener(`click`, (e) => {
    const trigger = e.target.closest(`.content-grid .-toggle-categories`)
    if (trigger) {
      e.preventDefault()
      const show = trigger !== activeTrigger
      if (activeTrigger) doHide()
      if (show) doShow(trigger)
    } else if (activeTrigger) {
      if (!e.target.closest(`.-hidden-categories`)) doHide()
    }
  })
})
