import { domReady } from "../utils/dom"

domReady(() => {
  const questionBlock = document.querySelector(`.digitaljakten`)
  if (!questionBlock) return

  window.scrollTo(0, questionBlock.getBoundingClientRect().top)

  const form = document.querySelector(`.digitaljakten__answer form`)
  if (!form) return

  const input = form.querySelector(`.input`)
  const success = document.querySelector(`.-success`)

  form.addEventListener(`ajax:success`, (e) => {
    const response = e.detail[0]

    input.classList.remove(`--wrong`)

    if (response.success) {
      success.innerHTML = response.content

      setTimeout(() => {
        success.classList.add(`--visible`)
        success.querySelector(`.-next-question button`).focus()
      }, 100)
    } else {
      setTimeout(() => {
        input.classList.add(`--wrong`)
        input.value = ``
        input.placeholder = `Det var tyvärr fel svar, försök igen!`
        input.focus()

        input.addEventListener(`keydown`, () => {
          input.classList.remove(`--wrong`)
        })
      }, 10)
    }
  })

  const timer = questionBlock.querySelector(`.-timer`)

  let countDownDate = new Date()

  if (timer.getAttribute(`data-start`) === `true`) {
    localStorage.setItem(`startDate`, countDownDate)
  } else {
    countDownDate = localStorage.getItem(`startDate`)
    if (countDownDate) {
      countDownDate = new Date(countDownDate)
    } else {
      countDownDate = new Date()
      localStorage.setItem(`startDate`, countDownDate)
    }
  }

  // Update the count down every 1 second
  const counterUpdate = setInterval(() => {
    // Get todays date and time
    const now = new Date().getTime()

    // Find the distance between now an the count down date
    const distance = now - countDownDate.getTime()

    // Time calculations for days, hours, minutes and seconds
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)

    // Output the result in an element with id="demo"
    timer.innerHTML =
      (`0` + minutes).slice(-2) + `:` + (`0` + seconds).slice(-2)
  }, 1000)
})
