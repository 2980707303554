import { getElements } from "@oddcamp/js-utils/src/selector"
import { addClass, removeClass } from "@oddcamp/js-utils/src/attribute"
import { addEventListener } from "@oddcamp/js-utils/src/event"

import { domReady } from "../../utils/dom"

domReady(() => {
  const clearToggle = document.querySelector(`.-clear-toggle`)
  const clearCardsContainer = document.querySelector(`.search-clear`)

  if (clearToggle) {
    addEventListener(clearToggle, `click`, () => {
      if (clearCardsContainer.classList.contains(`--expanded`)) {
        removeClass(clearCardsContainer, `--expanded`)
        removeClass(clearToggle, `--expanded`)
      } else {
        addClass(clearCardsContainer, `--expanded`)
        addClass(clearToggle, `--expanded`)
      }
    })
  }

  const clearCards = getElements(`.-clear-button`)
  const searchCheckboxes = getElements(`.checkbox input`)

  if (clearCards) {
    clearCards.forEach((clearCard) => {
      addEventListener(clearCard, `click`, () => {
        // clear single
        if (clearCard.hasAttribute(`data-filter-id`)) {
          searchCheckboxes.forEach((searchCheckbox) => {
            if (
              searchCheckbox.value == clearCard.getAttribute(`data-filter-id`)
            ) {
              searchCheckbox.checked = false
              document.querySelector(`.search-form`).submit()
            }
          })
        }
        // clear all
        if (clearCard.hasAttribute(`data-clear-all`)) {
          searchCheckboxes.forEach((searchCheckbox) => {
            if (searchCheckbox.checked) {
              searchCheckbox.checked = false
              document.querySelector(`.search-form`).submit()
            }
          })
        }
      })
    })
  }
})
