import { getElements } from "@oddcamp/js-utils/src/selector"
import { debounce } from "@oddcamp/js-utils/src/function"

import { domReady } from "../../utils/dom"

// sets [data-omasks] on an element

domReady(() => {
  if (!document.querySelector(`.search-form`)) return

  const elems = getElements(`.js--omasks`)

  const setAttrs = (elem) => {
    const edges = []

    if (elem.scrollWidth > elem.offsetWidth) {
      if (elem.scrollLeft > 5) edges.push(`left`)
      if (elem.scrollLeft + elem.offsetWidth <= elem.scrollWidth - 5)
        edges.push(`right`)
    }

    if (elem.scrollHeight > elem.offsetHeight) {
      if (elem.scrollTop > 5) edges.push(`top`)
      if (elem.scrollTop + elem.offsetHeight <= elem.scrollHeight - 5)
        edges.push(`bottom`)
    }

    if (edges.length) {
      elem.setAttribute(`data-omasks`, edges.join(` `))
    } else {
      elem.removeAttribute(`data-omasks`)
    }
  }

  elems.forEach((elem) => {
    elem.addEventListener(`scroll`, () => {
      setAttrs(elem)
    })

    window.addEventListener(
      `resize`,
      debounce(300, () => {
        setAttrs(elem)
      })
    )

    setAttrs(elem)
  })
})
