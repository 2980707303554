import Cookies from "js-cookie"

import { domReady } from "../../utils/dom"

domReady(() => {
  const container = document.querySelector(`.header-message`)
  if (!container) return

  const button = container.querySelector(`.-close`)
  button.addEventListener(`click`, (e) => {
    e.preventDefault()
    container.style.height = `${container.offsetHeight}px`
    setTimeout(() => container.classList.add(`--disappear`), 50)
    Cookies.set(`header-message-closed`, container.getAttribute(`data-id`), {
      expires: 365,
    })
  })
})
