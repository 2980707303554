import { getElements } from "@oddcamp/js-utils/src/selector"
import { addEventListener } from "@oddcamp/js-utils/src/event"
import { get } from "js-cookie"

import { domReady } from "../../utils/dom"

domReady(() => {
  const togglers = getElements(`.js--toggle`)
  const event = new Event(`change`)

  if (togglers.length) {
    togglers.forEach((toggle) => {
      addEventListener(toggle, `click`, (e) => {
        e.preventDefault()

        toggle.closest(`li`).classList.toggle(`--expanded`)
      })
    })
  }

  const triggers = getElements(`.overflow .-tree .-parent`)

  if (triggers.length) {
    triggers.forEach((trigger) => {
      getElements(
        `ul .checkbox input[type=checkbox]`,
        trigger.closest(`li`)
      ).forEach((checkbox) => {
        if (
          checkbox.checked &&
          !trigger.closest(`li`).classList.contains(`--expanded`)
        ) {
          trigger.closest(`li`).classList.add(`--expanded`)
        }
      })

      trigger.addEventListener(`change`, () => {
        trigger.dispatchEvent(event)

        if (!trigger.closest(`li`).classList.contains(`--expanded`)) {
          trigger.closest(`li`).classList.add(`--expanded`)
        }

        getElements(
          `ul .checkbox input[type=checkbox]`,
          trigger.closest(`li`)
        ).forEach((checkbox) => {
          if (!checkbox.checked == true) {
            checkbox.checked = trigger.checked
            checkbox.dispatchEvent(event)
          }

          addEventListener(checkbox, `change`, () => {
            if (trigger.checked == true) {
              trigger.checked = checkbox.checked
              trigger.dispatchEvent(event)
            }
          })
        })
      })
    })
  }
})
