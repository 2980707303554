import Flickity from "flickity"
import A11yDialog from "a11y-dialog"
import { delegateEventListener } from "@oddcamp/js-utils/src/event"
import { getElements } from "@oddcamp/js-utils/src/selector"

let dialogInst = null
let flickityInst = null
let svgLeft = null
let svgRight = null
let svgClose = null

function cacheSvgHtml() {
  if (!svgLeft) {
    const el = document.querySelector(`.js--imglightbox-svg-left`)
    if (el) svgLeft = el.innerHTML
  }

  if (!svgRight) {
    const el = document.querySelector(`.js--imglightbox-svg-right`)
    if (el) svgRight = el.innerHTML
  }

  if (!svgClose) {
    const el = document.querySelector(`.js--imglightbox-svg-close`)
    if (el) svgClose = el.innerHTML
  }
}

function replaceSvgArrows() {
  if (svgLeft) {
    const button = document.querySelector(
      `.imglightbox .flickity-button.previous`
    )
    if (button) button.innerHTML = svgLeft
  }

  if (svgRight) {
    const button = document.querySelector(`.imglightbox .flickity-button.next`)
    if (button) button.innerHTML = svgRight
  }
}

function togglePageScroll(mode = true) {
  document.documentElement.classList.toggle(`html--disable-scroll`, mode)
}

function close() {
  if (dialogInst) {
    dialogInst.destroy()
    dialogInst = null
  }
  if (flickityInst) {
    flickityInst.destroy()
    flickityInst = null
  }
  const el = document.querySelector(`.imglightbox`)
  if (el) el.parentNode.removeChild(el)
}

function compileHtml(images) {
  return `
    <div class="imglightbox js--a11ydialog" id="imglightbox" aria-hidden="true">
      <div role="dialog" aria-labelledby="imglightbox-heading">
        <h1 id="imglightbox-heading" class="is-vhidden">Image gallery</h1>

        <div class="imglightbox__carousel">
          ${images
            .map(
              (image) => `
            <div class="-slide">
              <img
                data-flickity-lazyload-src="${image.src}"
                alt="${image.alt || ``}"
                ${
                  image.srcset
                    ? `data-flickity-lazyload-srcset="${image.srcset}"`
                    : ``
                }
                ${image.sizes ? `sizes="${image.sizes}"` : ``}
              />
            </div>
          `
            )
            .join(``)}
        </div>

        <button
          type="button"
          class="imglightbox__close"
          title="Close"
          data-a11y-dialog-hide
        >
          ${svgClose || `Close`}
        </button>
      </div>
    </div>
  `
}

function init(trigger) {
  cacheSvgHtml()

  const galleryName = trigger.getAttribute(`data-imglightbox`) || null
  const images = []
  let triggerIndex = 0
  let relatedTriggers = []

  if (galleryName) {
    relatedTriggers = getElements(`[data-imglightbox="${galleryName}"]`)
  } else {
    relatedTriggers.push(trigger)
  }

  relatedTriggers.forEach((item, i) => {
    images.push({
      src: item.getAttribute(`href`),
      srcset: item.getAttribute(`data-imglightbox-srcset`) || null,
      sizes: item.getAttribute(`data-imglightbox-sizes`) || null,
      alt: item.getAttribute(`title`),
    })

    if (item === trigger) triggerIndex = i
  })

  document.body.insertAdjacentHTML(`beforeend`, compileHtml(images))
  const container = document.querySelector(`.imglightbox`)
  const carousel = container.querySelector(`.imglightbox__carousel`)

  flickityInst = new Flickity(carousel, {
    lazyLoad: 2,
    setGallerySize: false,
    prevNextButtons: relatedTriggers.length > 1,
    pageDots: relatedTriggers.length > 1,
    initialIndex: triggerIndex,
  })
  replaceSvgArrows()

  dialogInst = new A11yDialog(
    container,
    document.querySelectorAll(`body > *:not(.js--a11ydialog)`)
  )

  dialogInst
    .on(`show`, () => {
      togglePageScroll(true)
      carousel.focus()
    })
    .on(`hide`, () => {
      togglePageScroll(false)
      close()
    })

  dialogInst.show()
}

delegateEventListener(`[data-imglightbox]`, `click`, (e, target) => {
  e.preventDefault()
  init(target)
})
