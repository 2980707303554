import scrollIntoView from "scroll-into-view"
import { getElements } from "@oddcamp/js-utils/src/selector"
import "iframe-resizer/js/iframeResizer"

import { domReady } from "../utils/dom"

domReady(() => {
  const iframes = getElements(`.embedded-form__form iframe`)
  if (!iframes.length) return

  iframes.forEach((iframe) => {
    window.iFrameResize(
      {
        checkOrigin: false,
        resizedCallback: () => {
          iframe.setAttribute(`scrolling`, `no`)
        },
      },
      iframe
    )

    // scroll to top if iframe has been reloaded (i.e. the form has been submitted)
    let timesLoaded = 0
    iframe.addEventListener(`load`, () => {
      timesLoaded++
      if (timesLoaded > 1) {
        scrollIntoView(iframe, {
          time: 300,
          align: {
            top: 0,
            topOffset: 0,
          },
        })
      }
    })
  })
})
