import { domReady } from "../utils/dom"

domReady(() => {
  const posterPreview = document.querySelector(`.poster-preview`)
  if (!posterPreview) return

  const colorPickers = document.querySelectorAll(
    `.poster-preview__backgrounds ul li`
  )
  if (!colorPickers) return

  colorPickers.forEach((colorPicker) => {
    colorPicker.addEventListener(`click`, (e) => {
      colorPickers.forEach((element) => {
        element.classList.remove(`--active`)
      })

      const colorClass = colorPicker.classList

      colorPicker.classList.add(`--active`)

      const posterPreviewInner = posterPreview.querySelector(
        `.poster-preview__inner`
      )

      posterPreviewInner.classList = `poster-preview__inner ` + colorClass
    })
  })
})
