import ahoy from "ahoy.js"

import { enableAutoEventAnalytics } from "@oddcamp/analytics"
enableAutoEventAnalytics()

// Rails example
// <%= link_to vite_image_tag("images/logos/#{bg_color_type == 'dark' ? 'white' : 'purple'}.svg", alt: 'Bilda logo'), root_path, data: { 'track-event': 'click', 'ga-category': 'ga cat', 'ga-action': 'ga action', 'ga-label': 'ga label', 'ga-value': '1' } %>

// HTML Example
// <a href="http://google.com" target="_blank" data-track-event="click" data-ga-category="google cat" data-ga-label="google label" data-ga-action="google action" data-ga-value="1337">CLICK ME</a>

document.addEventListener(
  `DOMContentLoaded`,
  function () {
    let internal = location.host.replace(`www.`, ``)
    internal = new RegExp(internal, `i`)

    const a = document.getElementsByTagName(`a`)

    for (let i = 0; i < a.length; i++) {
      const href = a[i].host // set the host of each link
      if (!internal.test(href)) {
        const link = a[i]

        a[i].setAttribute(`target`, `_blank`)
        a[i].setAttribute(`rel`, `noopener`)
        a[i].setAttribute(`data-external`, `true`)
      }
    }
  },
  false
)

/**
 * To track GA add the following properties to the data-track attribute:
 * ga_category (required)
 * ga_action (required)
 * ga_label
 * ga_value
 */
function trackGoogleAnalyticsEvent(obj) {
  // Make sure GA is loaded
  if (window.ga) {
    // Set value to null unless it's a number.
    if (isNaN(obj.ga_value)) {
      obj.ga_value = null
    }

    // Make sure both category and action is a string to send track event.
    if (
      typeof obj.ga_category === `string` &&
      typeof obj.ga_action === `string`
    ) {
      ga(
        ga_universal_analytics_options.tracker_name + `.send`,
        `event`,
        obj.ga_category,
        obj.ga_action,
        obj.ga_label,
        obj.ga_value
      )
    }
  }
}

// Select all elements with data-track-event="click"
// and setup event tracking for them
if (window.ga) {
  document.addEventListener(
    `DOMContentLoaded`,
    function () {
      const ga_event_clicks = document.querySelectorAll(
        `[data-track-event='click']`
      )
      ga_event_clicks.forEach(function (item) {
        item.addEventListener(`click`, (e) => {
          trackGoogleAnalyticsEvent({
            ga_category: item.dataset.gaCategory || null,
            ga_action: item.dataset.gaAction || null,
            ga_label: item.dataset.gaLabel || null,
            ga_value: item.dataset.gaValue || null,
          })
        })
      })
    },
    false
  )
}

function eventTracking() {
  if (ahoy || window.mixpanel) {
    return true
  } else {
    return
  }
}

function eventTrackingProps(props) {
  try {
    JSON.parse(props)
  } catch (e) {
    return
  }
  return JSON.parse(props)
}

function eventTrackingTrack(name, props) {
  if (eventTracking() && typeof name === `string`) {
    if (ahoy) {
      ahoy.track(name, props)
    }

    if (window.mixpanel) {
      mixpanel.track(name, props)
    }
  }
}

if (eventTracking()) {
  // Add event listener to any element with the data attribute
  // [data-track-event='click']
  document.addEventListener(
    `DOMContentLoaded`,
    function () {
      const event_analytics_clicks = document.querySelectorAll(
        `[data-track-event='click']`
      )
      event_analytics_clicks.forEach(function (item) {
        item.addEventListener(`click`, (e) => {
          eventTrackingTrack(
            item.dataset.eventName,
            eventTrackingProps(item.dataset.eventProperties)
          )
        })
      })
    },
    false
  )

  // Add event listener to any element with the data attribute
  // [data-external='true']
  document.addEventListener(
    `DOMContentLoaded`,
    function () {
      const external_links = document.querySelectorAll(`[data-external='true']`)
      external_links.forEach(function (item) {
        item.addEventListener(`click`, (e) => {
          eventTrackingTrack(`External Link`, {
            url: item.getAttribute(`href`),
          })
          trackGoogleAnalyticsEvent({
            ga_category: `External Link`,
            ga_action: item.getAttribute(`href`),
          })
        })
      })
    },
    false
  )

  // Track any events defined in AET
  if (window.AET) {
    window.AET.forEach((item) => {
      eventTrackingTrack(item.name, item.props)
    })
  }

  window.addEventListener(`AET`, (e) => {
    eventTrackingTrack(e.detail.name, e.detail.props)
  })
}
