import { domReady } from "../../utils/dom"

domReady(() => {
  const container = document.querySelector(`.header`)
  const toggler = document.querySelector(`.header-toggle button`)
  if (!container || !toggler) return

  toggler.addEventListener(`click`, (e) => {
    e.preventDefault()
    container.classList.toggle(`header--expanded`)
  })
})
