import { domReady } from "../../utils/dom"

domReady(() => {
  const container = document.querySelector(`.header-nav`)
  if (!container) return

  const dropdowns = container.querySelectorAll(`.header-nav__parent`)

  dropdowns.forEach((dropdown) => {
    dropdown.addEventListener(`click`, (e) => {
      e.target.parentElement.classList.toggle(`-open`)
    })
  })
})
