import { domReady } from "../../utils/dom"

domReady(() => {
  const filterTabs = document.querySelector(`.filter-tabs`)
  const activeFilter = document.querySelector(`.js--active-tab`)

  if (!filterTabs) return

  const activeFilterPosition = activeFilter.getBoundingClientRect()
  filterTabs.scrollLeft = activeFilterPosition.left - 40
})
