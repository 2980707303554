// show less/more toggling

import { getElements } from "@oddcamp/js-utils/src/selector"
import { toggleClass } from "@oddcamp/js-utils/src/attribute"
import scrollIntoView from "scroll-into-view"

import { domReady } from "../../utils/dom"

domReady(() => {
  const containers = getElements(`.venues-list`)
  if (!containers.length) return

  containers.forEach((container) => {
    const toggler = container.querySelector(`.-toggler button`)
    const venues = getElements(`.venues-list__venue`, container)

    if (!toggler || !venues.length) return

    const list = container.querySelector(`.venues-list__items`)
    const limit = parseInt(container.getAttribute(`data-limit`))
    const tglText = toggler.querySelector(`span`)
    const tglTextOn = tglText.textContent
    const tglTextOff = toggler.getAttribute(`data-text-off`)
    let isOn = false

    toggler.addEventListener(`click`, (e) => {
      e.preventDefault()
      isOn = !isOn
      let i = 0

      if (!isOn) {
        scrollIntoView(list)
      }

      venues.forEach((venue) => {
        if (venue.classList.contains(`--search-hidden`)) return

        i++
        if (isOn) {
          venue.classList.remove(`--hidden`)
          if (i > limit) venue.classList.add(`--revealed`)
        } else {
          venue.classList.remove(`--revealed`)
          if (i > limit) venue.classList.add(`--hidden`)
        }
      })

      toggleClass(toggler, `--off`, isOn)
      tglText.textContent = isOn ? tglTextOff : tglTextOn
    })
  })
})
