import { addClass, removeClass } from "@oddcamp/js-utils/src/attribute"
import { debounce } from "@oddcamp/js-utils/src/function"

import { domReady } from "../../utils/dom"

domReady(() => {
  const smoothAnchor = document.querySelector(`.js--smooth-anchor`)
  const searchHeader = document.querySelector(`.search-header`)

  if (!smoothAnchor) return

  document.addEventListener(
    `scroll`,
    debounce(100, () => {
      if (window.scrollY > searchHeader.offsetTop + searchHeader.offsetHeight) {
        addClass(smoothAnchor, `--scrolled`)
      } else {
        removeClass(smoothAnchor, `--scrolled`)
      }
    })
  )
})
