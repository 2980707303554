import { getElements } from "@oddcamp/js-utils/src/selector"
import loadGoogleMapsApi from "load-google-maps-api"

import { domReady } from "../utils/dom"

const options = {
  key: `AIzaSyB5g-Glx49DFTJcQkhYgpRN3TxEtJ2Emkw&`,
}

const mapOptions = {
  zoom: 15,
  disableDefaultUI: false,
  controlSize: 26,
  mapTypeControl: false,
  styles: [
    {
      featureType: `administrative`,
      elementType: `labels.text.fill`,
      stylers: [
        {
          color: `#351539`,
        },
      ],
    },
    {
      featureType: `road`,
      elementType: `all`,
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: `road.highway`,
      elementType: `all`,
      stylers: [
        {
          visibility: `simplified`,
        },
      ],
    },
    {
      featureType: `water`,
      elementType: `all`,
      stylers: [
        {
          color: `#adddf0`,
        },
        {
          visibility: `on`,
        },
      ],
    },
  ],
}

const loadMaps = (googleMapsApi, elements) => {
  elements.forEach((element) => {
    const address = element.getAttribute(`data-address`)
    const lat = parseFloat(element.getAttribute(`data-lat`)) || null
    const lng = parseFloat(element.getAttribute(`data-lng`)) || null

    const load = (location) => {
      const apiMap = new googleMapsApi.Map(element, {
        ...mapOptions,
        center: location,
      })

      const infowindow = new googleMapsApi.InfoWindow({
        content: `<p>${address}</p>`,
      })

      const marker = new googleMapsApi.Marker({
        position: location,
        map: apiMap,
        title: address,
      })

      marker.addListener(`click`, function () {
        infowindow.open(apiMap, marker)
      })
    }

    if (lat !== null && lng !== null) {
      load({ lat, lng })
    } else if (address) {
      const geocoder = new googleMapsApi.Geocoder()
      geocoder.geocode({ address }, (results, status) => {
        if (status === `OK` && results.length) {
          load({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          })
        } else {
          element.classList.add(`--location-not-found`)
        }
      })
    } else {
      element.classList.add(`--location-not-found`)
    }
  })
}

domReady(() => {
  const elements = getElements(`.map`)
  if (!elements.length) return

  loadGoogleMapsApi(options).then((googleMapsApi) =>
    loadMaps(googleMapsApi, elements)
  )
  // .catch(function (error) {
  //   console.error(error);
  // });
})
