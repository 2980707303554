import { getElements } from "@oddcamp/js-utils/src/selector"
import { addEventListener } from "@oddcamp/js-utils/src/event"
// import Popper from 'popper.js';

import { domReady } from "../../utils/dom"

// TODO: a11y

// const popperConfig = {
//   placement: 'bottom-end',
//   modifiers: {
//     flip: {
//       behavior: ['bottom'],
//     },
//   },
// };

let activeTrigger = null
let activeTarget = null
// let activePopper = null;

const doShow = (trigger) => {
  const name = trigger.getAttribute(`href`).replace(`#`, ``)
  const target = document.querySelector(`#${name}`)

  target.classList.add(`--active`)
  trigger.classList.add(`--active`)
  // const popper = new Popper(trigger, target, popperConfig);

  // target.setAttribute('tabindex', 0);
  // target.focus();

  activeTrigger = trigger
  activeTarget = target
  // activePopper = popper;

  if (window.innerWidth < 640 && activeTarget.id != `filters-sorting`) {
    document.body.style.position = `fixed`
    document.body.style.top = `-${window.scrollY}px`
  }
}

const doHide = () => {
  activeTrigger.classList.remove(`--active`)
  activeTarget.classList.remove(`--active`)
  // activePopper.destroy();

  activeTrigger = null
  activeTarget = null
  // activePopper = null;

  if (window.innerWidth < 640) {
    const scrollY = document.body.style.top
    document.body.style.position = ``
    document.body.style.top = ``
    window.scrollTo(0, parseInt(scrollY || `0`) * -1)
  }
}

domReady(() => {
  if (!document.querySelector(`.search-form`)) return

  const tiggersClose = getElements(`.js--filter-close`)

  tiggersClose.forEach((tiggerClose) => {
    addEventListener(tiggerClose, `click`, (e) => {
      e.preventDefault()
      doHide()
    })
  })

  document.addEventListener(`click`, (e) => {
    const trigger = e.target.closest(`.js--filter-trigger`)

    if (trigger) {
      e.preventDefault()
      const show = trigger !== activeTrigger
      if (activeTrigger) doHide()
      if (show) doShow(trigger)
    } else if (activeTrigger) {
      if (!e.target.closest(`.-optgroup`)) doHide()
    }
  })
})
