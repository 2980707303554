// scrolls into view on accordion-mode tab click
// TODO: a11y attributes
// TODO: auto-select by hash in URL

import scrollIntoView from "scroll-into-view"
import { addEventListener } from "@oddcamp/js-utils/src/event"

import { domReady } from "../utils/dom"

domReady(() => {
  const tgls = document.querySelectorAll(`.regional-contacts-region__header a`)
  if (!tgls.length) return

  addEventListener(tgls, `click`, (e) => {
    scrollIntoView(e.currentTarget, {
      time: 300,
      align: {
        top: 0,
        topOffset: 0,
      },
    })
  })
})
