import { getElements } from "@oddcamp/js-utils/src/selector"
import { addEventListener } from "@oddcamp/js-utils/src/event"
import { addClass, removeClass } from "@oddcamp/js-utils/src/attribute"
import { debounce } from "@oddcamp/js-utils/src/function"

import { domReady } from "../../utils/dom"

domReady(() => {
  const optgroups = getElements(`.-optgroup`)
  const placesSwitch = getElements(`.js--places`)
  const clearInput = document.querySelector(`.js--clear-input`)
  if (!optgroups.length) return

  if (document.querySelector(`.search-form__query input`).value) {
    addClass(clearInput, `--active`)
    addEventListener(clearInput, `click`, () => {
      document.querySelector(`.search-form__query input`).value = ``
      removeClass(clearInput, `--active`)
    })
  }

  const event = new Event(`change`)

  const cleanInputs = (container) => {
    const inputs = getElements(`input[type="checkbox"]`, container)

    if (!inputs.length) return

    inputs.forEach((input) => {
      if (input.checked) {
        input.checked = false
        input.dispatchEvent(event)
      }
    })
  }

  optgroups.forEach((optgroup) => {
    const ctaApply = optgroup.querySelector(`.-cta-apply`)
    const ctaClear = optgroup.querySelector(`.-cta-clear`)
    const inputs = optgroup.querySelectorAll(`input`)
    if (!ctaApply || !ctaClear || !inputs.length) return

    addEventListener(ctaClear, `click`, (e) => {
      e.preventDefault()

      ctaClear.disabled = true
      ctaApply.disabled = true

      inputs.forEach((input) => {
        if (input.checked) {
          input.checked = false
          input.dispatchEvent(event)
        }
      })
    })

    placesSwitch.forEach((placeSwitchTrigger) => {
      addEventListener(placeSwitchTrigger, `click`, () => {
        cleanInputs(document.getElementById(placeSwitchTrigger.dataset.clean))
      })
    })

    inputs.forEach((input) => {
      if (input.checked) {
        ctaApply.disabled = false
        ctaClear.disabled = false
      }

      addEventListener(input, `change`, () => {
        if (ctaApply.disabled) {
          ctaApply.disabled = false
        }

        if (input.checked && ctaClear.disabled) {
          ctaClear.disabled = false
        }
      })
    })

    // splice the array
    const getUnique = (array) => {
      const newArr = array
      let h
      let i
      let j

      for (h = 0; h < array.length; h++) {
        const curItem = array[h]
        let foundCount = 0

        for (i = 0; i < array.length; i++) {
          if (array[i] == array[h]) foundCount++
        }
        if (foundCount > 1) {
          for (j = 0; j < newArr.length; j++) {
            if (newArr[j] == curItem) {
              newArr.splice(j, 1)
              j--
            }
          }
        }
      }

      return newArr
    }

    const subjectCheckboxes = getElements(
      `#search-form-subjects .checkbox input`
    )
    const subjectSearchData = []

    const placeCheckboxes = getElements(`#search-form-places .checkbox input`)
    const placeSearchData = []

    if (subjectCheckboxes) {
      subjectCheckboxes.forEach((subjectCheckbox) => {
        if (subjectCheckbox.checked) {
          subjectSearchData.push(subjectCheckbox.nextSibling.data)
        }

        subjectCheckbox.addEventListener(
          `change`,
          debounce(100, () => {
            subjectSearchData.push(subjectCheckbox.nextSibling.data)

            document.querySelector(
              `a[href='#search-form-subjects'] .inner`
            ).innerHTML = getUnique(subjectSearchData).length + ` ämnen valda`

            document.querySelector(
              `#search-form-subjects .-cta-apply span`
            ).innerHTML = getUnique(subjectSearchData).length
          })
        )
      })
    }

    if (placeCheckboxes) {
      placeCheckboxes.forEach((placeCheckbox) => {
        if (placeCheckbox.checked) {
          placeSearchData.push(placeCheckbox.nextSibling.data)
        }

        placeCheckbox.addEventListener(
          `change`,
          debounce(100, () => {
            placeSearchData.push(placeCheckbox.nextSibling.data)

            document.querySelector(
              `a[href='#search-form-places'] .inner`
            ).innerHTML = getUnique(placeSearchData).length + ` platser valda`

            document.querySelector(
              `#search-form-places .-cta-apply span`
            ).innerHTML = getUnique(placeSearchData).length
          })
        )
      })
    }

    if (subjectSearchData.length > 0) {
      document.querySelector(
        `a[href='#search-form-subjects'] .inner`
      ).innerHTML = getUnique(subjectSearchData).length + ` ämnen valda`

      document.querySelector(
        `#search-form-subjects .-cta-apply span`
      ).innerHTML = getUnique(subjectSearchData).length
    }

    if (placeSearchData.length > 0) {
      document.querySelector(`a[href='#search-form-places'] .inner`).innerHTML =
        getUnique(placeSearchData).length + ` platser valda`

      document.querySelector(`#search-form-places .-cta-apply span`).innerHTML =
        getUnique(placeSearchData).length
    }
  })
})
