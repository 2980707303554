import { domReady } from "../utils/dom"
import { debounce } from "@oddcamp/js-utils/src/function"

domReady(() => {
  const form = document.querySelector(`.arrangement__form .-form form`)
  if (!form) return

  const spinner = document.querySelector(`.arr-form-spinner`)
  if (!spinner) return
  spinner.classList.remove(`--visible`)

  form.addEventListener(`submit`, () => {
    spinner.classList.add(`--visible`)
  })

  form.addEventListener(`ajax:success`, () => {
    spinner.classList.remove(`--visible`)
  })

  form.addEventListener(`ajax:error`, () => {
    spinner.classList.remove(`--visible`)
  })

  if (localStorage) {
    const textInputs = form.querySelectorAll(
      `input[type=text], input[type=email], textarea`
    )

    const lsName = (name) => `${window.location.pathname}_${name}`

    for (let i = 0; i < textInputs.length; i++) {
      textInputs[i].value = localStorage.getItem(lsName(textInputs[i].name))

      textInputs[i].addEventListener(`keyup`, (e) => {
        localStorage.setItem(lsName(e.target.name), e.target.value)
      })
    }

    const radioInputs = form.querySelectorAll(`input[type=radio]`)

    for (let i = 0; i < radioInputs.length; i++) {
      const el = radioInputs[i]
      const storedVal = localStorage.getItem(lsName(el.name))
      if (storedVal && storedVal == el.value) el.checked = true

      el.addEventListener(`change`, (e) => {
        localStorage.setItem(lsName(e.target.name), e.target.value)
      })
    }

    const checkboxInputs = form.querySelectorAll(`input[type=checkbox]`)

    for (let i = 0; i < checkboxInputs.length; i++) {
      const el = checkboxInputs[i]
      const storedVal = localStorage.getItem(lsName(el.name))
      if (storedVal) el.checked = JSON.parse(storedVal)

      checkboxInputs[i].addEventListener(`change`, (e) => {
        localStorage.setItem(lsName(e.target.name), e.target.checked)
      })
    }

    const allInputs = form.querySelectorAll(`input, textarea`)
    form.addEventListener(`ajax:success`, () => {
      for (let i = 0; i < allInputs.length; i++) {
        localStorage.removeItem(lsName(allInputs[i].name))
      }
    })
  }

  // Show/Hide personnummer
  const persNumRadio = form.querySelectorAll(`input[name='person[number]']`)
  if (!persNumRadio) return

  const personnummer = form.querySelector(`.personnummer`)
  const lma = form.querySelectorAll(`.lma`)

  const fields = {
    personnummer: personnummer,
    lma: [...lma],
  }

  const parentEl = form.querySelector(`.person-number-wrapper`)
  const submitBtn = form.querySelector(`button[type=submit]`)

  // Removing childs from DOM
  removeChilds()

  // First time show selected, value comes also from localStorage
  persNumRadio.forEach((el) => {
    if (el.checked) {
      showSelected(el)
    }

    el.addEventListener(`click`, (e) => {
      removeChilds()
      showSelected(e.target)
    })
  })

  function removeChilds() {
    parentEl.innerHTML = ``
  }

  // Show selected
  function showSelected(sel) {
    // Remove disabled on submit just in case
    submitBtn.removeAttribute(`disabled`)

    if (Array.isArray(fields[sel.value])) {
      parentEl.append(...fields[sel.value])
    } else {
      parentEl.append(fields[sel.value])
    }
  }

  // Validate while typing
  personnummer.addEventListener(
    `keyup`,
    debounce(500, (e) => {
      validatePersonnummer(e.target)
    })
  )

  function validatePersonnummer(target) {
    const id = target.value
    target.parentElement.classList.add(`fetching`)
    fetch(`/api/v1/personal_numbers/${id}/validate`).then((response) => {
      target.parentElement.classList.remove(`valid`, `not-valid`, `fetching`)
      submitBtn.removeAttribute(`disabled`)

      if (response.status == 200) {
        target.parentElement.classList.add(`valid`)
      } else {
        submitBtn.setAttribute(`disabled`, ``)
        target.parentElement.classList.add(`not-valid`)
      }
    })
  }
})
