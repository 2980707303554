import { addClass, removeClass } from "@oddcamp/js-utils/src/attribute"
import { debounce } from "@oddcamp/js-utils/src/function"

import { domReady } from "../../utils/dom"

domReady(() => {
  const filterSummary = document.querySelector(`.js--filter-summary`)
  if (!filterSummary) return

  const searchHeader = document.querySelector(`.search-header`)

  document.addEventListener(
    `scroll`,
    debounce(100, () => {
      if (window.scrollY > searchHeader.offsetTop + searchHeader.offsetHeight) {
        addClass(filterSummary, `--scrolled`)
      } else {
        removeClass(filterSummary, `--scrolled`)
      }
    })
  )
})
