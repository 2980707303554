import { addEventListener } from "@oddcamp/js-utils/src/event"
import { toggleClass } from "@oddcamp/js-utils/src/attribute"

import { domReady } from "../../utils/dom"

domReady(() => {
  // Submit form when hitting enter in the query
  const form = document.querySelector(`.search-form`)
  const queryInput = document.getElementById(`q`)
  if (form && queryInput) {
    queryInput.addEventListener(
      `keydown`,
      function (e) {
        if (e.key === `Enter`) {
          form.submit()
        }
      },
      true
    )
  }

  // Trigger to show/hide query input on the /arr search form
  const trigger = document.querySelector(`.js--query-trigger`)
  const queryInputWrapper = document.querySelector(`.js--query-input`)
  if (trigger) {
    addEventListener(trigger, `click`, (e) => {
      e.preventDefault()

      toggleClass(trigger, `--interacted`)
      toggleClass(queryInputWrapper, `--hidden`)
    })
  }
})
