import { getElements } from "@oddcamp/js-utils/src/selector"
import { addClass, removeClass } from "@oddcamp/js-utils/src/attribute"
import Fuse from "fuse.js"

import { domReady } from "../../utils/dom"

// people list filtering by text input

domReady(() => {
  const containers = getElements(`.venues-list`)
  if (!containers.length) return

  const searchAttrs = [
    `id`,
    `title`,
    `email`,
    `phone`,
    `address1`,
    `address2`,
    `zip`,
    `city`,
  ]

  containers.forEach((container) => {
    const input = container.querySelector(`.-search input`)
    const venues = getElements(`.venues-list__venue`, container)
    const noResults = container.querySelector(`.venues-list__no-search-results`)
    if (!input || !noResults || !venues.length) return

    const cleanBtn = container.querySelector(`.-search button`)
    const toggler = container.querySelector(`.-toggler`)
    const togglerBtn = toggler ? toggler.querySelector(`button`) : undefined
    const limit = parseInt(container.getAttribute(`data-limit`) || 0)
    const searchData = []

    venues.forEach((venue, index) => {
      const data = { index }
      searchAttrs.forEach(
        (attr) => (data[attr] = venue.getAttribute(`data-${attr}`))
      )
      searchData.push(data)
    })

    const fuse = new Fuse(searchData, {
      threshold: 0.2,
      keys: searchAttrs,
    })

    input.addEventListener(`input`, () => {
      const searchString = input.value.trim()
      const limitTo =
        togglerBtn && togglerBtn.classList.contains(`--off`) ? 0 : limit

      removeClass(venues, `--revealed`)
      addClass(venues, `--hidden --search-hidden`)
      noResults.classList.remove(`--active`)
      const results = searchString.length
        ? fuse.search(searchString)
        : searchData

      if (results.length) {
        results.forEach((result, i) => {
          const index = searchData.findIndex((i) => i.index === result.index)
          const resultInDom = venues[index]

          resultInDom.classList.remove(`--search-hidden`)
          if (!limitTo || i < limitTo) {
            resultInDom.classList.remove(`--hidden`)
            resultInDom.classList.add(`--revealed`)
          }
        })

        if (toggler) {
          toggler.classList.toggle(`is-hidden`, results.length <= limit)
        }
      } else {
        noResults.classList.add(`--active`)
        if (toggler) toggler.classList.add(`is-hidden`)
      }

      cleanBtn.classList.toggle(`--active`, searchString.length)
    })

    cleanBtn.addEventListener(`click`, (e) => {
      e.preventDefault()
      input.value = ``
      input.dispatchEvent(new Event(`input`))
    })
  })
})
